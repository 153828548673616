@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Krona+One&display=swap');
.header{
    display: flex;
    position: sticky;
    top: 0;
    justify-content: space-between;
    z-index: 100;
    font-family: 'Mukta', sans-serif;
    background: transparent;
    transition: 0.5s;
}
.header.active{
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.401) 0%, rgba(250, 250, 250, 0.489) 100%);
    backdrop-filter: blur(4px);
}
.header a{
    text-decoration: none;
}
.header__logo{
    width: 100px;
    margin-top: 15px;
    margin-left: 50px;
    object-fit: contain;
    letter-spacing: 5px;
    font-weight: 400;
    font-size: 1.7rem;
    color: var(--text-primary);
    font-family: 'Krona One', sans-serif;
}
.header__logoP{
    font-size: 0.9rem;
    color: var(--text-primary);
    margin-left: 70px;
    margin-top: -15px;
    font-weight: 400;

}
.header__nav{
    display: flex;
    margin-top: 25px;
}
.header__nav a{
    text-decoration: none;
}
.header__option{
    display: flex;
    flex-direction: column;
    margin-left: 1.875rem;
    margin-right: 1.875rem;
    margin-top: 0px;
    transition: 0.5s;
    max-height: 60px;
}
.header__option:hover{
    background-color: rgba(30, 30, 30, 0.102);
    border-radius: 10px;
}

.header__option h1{
font-weight: 400;
font-size: 1.6rem;
color: var(--text-primary);
}
.hambuger-menu{
    display: none;
}
.hambuger-menu a{
    color: var(--text-primary);
}
.btn{
    font-size: 1.4rem;
    margin-top: -7px;
    
}

/* media querie */
@media screen and (max-width: 800px){
    .hambuger-menu{
        display: block;
    }
    .header__nav{
        display: none;
    }
    .header__logoP{
        font-size: 0.8rem;
        margin-left: 50px;
    }
    .header__option{
        display: flex;
        align-items: center;
        margin-top: 20px;
        transition: 1s;
        transform-origin: bottom;
    }
    .header__navPhone{
        display:flex;
        flex-direction: column;
        position: absolute;
          height: 15rem;
          width: 100%;
           background: linear-gradient(90.34deg, #AEFAEC 2.8%, rgba(0, 236, 194, 0.94) 99.69%);;
          backdrop-filter: blur(50px);
          box-shadow: 3px 4px 23px rgba(0, 0, 0, 0.25);
          top: 30px;
          right: 0%;
          z-index: 10;
       
      }
      .header__logo{
        width: 100px;
        margin-top: 10px;
        margin-left: 20px;
        font-size: 1.9rem;
    }
    .darkMode{
        margin-top: 10px;
        width: 80px;
        max-height: 30px;
    }
      .hambuger-menu {
          margin-top: 5px;
          margin-right: 20px;
      }

      .header__option:hover{
        border-bottom: 0px solid black;
    }

}
@media screen and (max-width: 400px){

    .hambuger-menu {
        margin-top: 2px;
        margin-right: 5px;
    }
    .header__logo{
        width: 50px;
        margin-top: 10px;
        margin-left: 20px;
        font-size: 1.2rem;
    }
    .header__logoP{
        font-size: 0.7rem;
        margin-left: 35px;
    }

}
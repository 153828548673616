.fotter{
    display: flex;
    width: 100%;
    height: 30vh;
    background-color: #141313;
    justify-content: space-around;
    font-family: 'Roboto', sans-serif;
    align-items: center;
    color: rgb(212, 212, 212);
    flex-wrap: wrap;
    margin-top: 50px;
}
.fotter_container{
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin-top: 30px;
}
.fotter_tittle h1{
    font-size: 1.8rem;
    font-weight: 400;
    font-family: 'Krona One', sans-serif;
    margin-bottom: 20px;
 
}
.fotter_tittle h2{
    font-size: 1.2rem;

}
.fotter_tittle h1:hover{
    color: #4ecdc4;
}
.fotter_content h1{
    font-size: 1.2rem;
    letter-spacing: 2px;
}
.fotter_content p {
    font-size: 0.8rem;
    max-width: 450px;
    font-weight: 200;
    margin-bottom: 1px;
    letter-spacing: 1px;
}
.fotter_right p{
    font-size: 0.8rem;
    text-align: center;
}
.fotter_p{
    font-size: 0.7rem;
}
@media screen and (max-width: 800px){
    .fotter_container{
       flex-direction: column;
       align-items: center;
    }
    .fotter{
        text-align: center;
    }
    .fotter_tittle h1{
        font-size: 1.3rem;
    }
    .fotter_tittle h2{
        font-size: 1rem;
        margin-top: -10px;
        margin-bottom: 20px;
    }
    .fotter_content h1{
        font-size: 1rem;
    }
    .fotter_content p{
       font-size: 0.7rem;
    }

}
@media screen and (max-width: 400px){
    .fotter_tittle h1{
        font-size: 1.3rem;
    }
    .fotter_tittle h2{
        font-size: 0.8rem;
    }
    .fotter{
        height: 40vh;
    }
    .fotter_content{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .fotter_content h1{
        font-size: 1rem;
    }
    .fotter_content p{
       font-size: 0.8rem;
    }
}
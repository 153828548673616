.about{
    display: flex;
    width: 100%;
    font-family: 'poppins', sans-serif;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
}
.about h1{
    font-size: 3rem;
    font-weight: 300;
    letter-spacing: 4px;
}
.about_content{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
}
.about_content h1{
    font-size: 2.5rem;
    font-weight: 300;
    letter-spacing: 1px;
}
.about_line{
    display: flex;
    height: 5px;
    width: 100%;
    background: linear-gradient(90deg, #FF0D9E 0%, #FF12E7 65.17%, rgba(64, 83, 255, 0.7) 100%);
    border-radius: 21px;
    margin-bottom: 50px;
}
.about_content p{
    font-size: 1.1rem;
    max-width: 900px;
    font-weight: normal;
    line-height: 30px;

}

@media screen and (max-width: 800px){
    .about h1{
        font-size: 2.9rem;
        font-weight: 300;
        letter-spacing: 2px;
    }
    .about_content h1{
        font-size: 2.5rem;
        font-weight: 300;
        letter-spacing: 1px;
    }

}
@media screen and (max-width: 400px){
    .about h1{
        font-size: 2.9rem;
        font-weight: 300;
        letter-spacing: 2px;
    }
    .about_content h1{
        font-size: 2rem;
        font-weight: 300;
        max-width: 330px;
        letter-spacing: 1px;
    }
    .about_content p{
        font-size: 1rem;
        max-width: 330px;
        font-weight: normal;
        line-height: 30px;
    
    }

}
.industry{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
}
.industry_title{
    font-size: 2.6rem;
    margin-top: 10px;
}
@media screen and (max-width: 800px){

    .industry_title{
        font-size: 2.1rem;
        text-align: center;
    }
    

}
@media screen and (max-width: 400px){
    .industry_title{
        font-size: 1.5rem;
        text-align: center;
    }

}
.functionCard{
    display: flex;
    width: 400px;
    max-height: 390px;
    background: linear-gradient(180deg, #DDFFF9 3.96%, rgba(250, 254, 253, 0.43) 100%);
    box-shadow: -3px 5px 18px rgba(0, 0, 0, 0.25);
    border-radius: 9px;
    flex-direction: column;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
    transition: 0.5s;
}
.functionCard img{
    display: flex;
    width: 270px;
    object-fit: contain;
    margin-top: 50px;
    border-radius: 10px;
}
.functionCard  h1{
    font-size: 1.3rem;
    margin-top: 30px;
    text-align: center;
    max-width: 340px;
    color: rgb(31, 32, 34);
}
.functionCard:hover{
    transform: translateY(-5px);

}

@media screen and (max-width: 800px){
    .functionCard{
        margin-bottom: 20px;
    }

}
@media screen and (max-width: 800px){
    .functionCard{
        display: flex;
        width: 290px;
        max-height: 300px;
        margin-left: 20px;
        margin-right: 20px;
    }
    .functionCard img{
        display: flex;
        width: 240px;
        object-fit: contain;
        margin-top: 25px;
        border-radius: 10px;
    }
    .functionCard  h1{
        font-size: 1rem;
        margin-top: 15px;
        text-align: center;
        max-width: 260px;
        color: rgb(31, 32, 34);
    }

}
.chart{
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 100px;
    margin-top: 20px;
}
.chart h1{
    margin-bottom: 50px;
    font-weight: normal;
    letter-spacing: 0.04em;
    font-size: 2.8rem;
}
.dash_board{
    display: flex;
    align-items: center;
    
}
.bar_container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 400px;
    width: 700px;
    background: #ececec;
    box-shadow: 1px 8px 8px -1px rgba(0, 0, 0, 0.22);
    border-radius: 17px;
    transition: 1s;
    margin-right: 40px;
}
.map_container{
    display: flex;
    height: 350px;
    width: 400px;
    background: #F2F2F2;
    box-shadow: 1px 8px 8px -1px rgba(0, 0, 0, 0.22);
    border-radius: 17px;
    transition: 1s;
    margin-left: 40px;
}
.map_container:hover{
    transform: translateY(-5px);

}
.map_container img{
    display: flex;
    width: 100%;
}
.bar_container:hover{
    transform: translateY(-5px);
}
.bar_container p{
    margin-bottom: 20px;
    margin-left: 60px;
    font-size: 1.1rem;
}
.country_list{
    width: 60%;
    margin-top: 50px;
}


@media screen and (max-width: 800px){
    .dash_board{
        flex-wrap: wrap;      
        justify-content: center;
    }
    .chart h1{
        letter-spacing: 0.04em;
        font-size: 2.4rem;
    }
    .bar_container{
        height: 350px;
        width: 400px;
        margin-right: 0px;
     
    }
    .map_container{
        height: 350px;
        width: 400px;
        margin-left: 0px;
        margin-top: 30px;
    }

}
@media screen and (max-width: 400px){
    .bar_container{
        height: 350px;
        width: 350px;
        margin-right: 0px;
     
    }
    .chart h1{
        font-size: 2.1rem;
    }
    .map_container{
        height: 300px;
        width: 300px;
        margin-left: 0px;
        margin-top: 30px;
    }
    .table th, td{
        font-size: 0.9rem;
    }
    .country_list{
        width: 100%;
        margin-top: 50px;
        text-align: center;
    }

}
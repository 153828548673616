::-webkit-scrollbar {
    display: none;
}

.home{
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    flex-wrap: wrap;
    font-family: 'Roboto', sans-serif;
}
.Home_container{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 100px;
}
.home_container_h1{
        margin-bottom: 50px;
        font-weight: normal;
        letter-spacing: 0.04em;
        font-size: 2.8rem;
        font-family: 'Poppins', sans-serif;
}
.home_line{
    display: flex;
    width: 600px;
    height: 2px;
    background: linear-gradient(90deg, #FF0D9E 0%, #FF12E7 65.17%, rgba(64, 83, 255, 0.7) 100%);
    border-radius: 21px;
   margin-top: 10px;
   margin-bottom: 50px;
}
.home_row{
    display: flex;
    justify-content: center;
    z-index: 1;
    margin-bottom: 100px;
}

.dropdown-toggle{
border-radius: 5px;
font-size: 1.5rem;
margin-left: -600px;
}
.carousel{
    display: flex;
    width: 400px;
    height: 500px;
}

@media screen and (max-width: 800px){
    .home_row{
        flex-wrap: wrap;
        margin-bottom: 5px;
    }
    .Home_container{
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        
    }
    .home_line{
        width: 370px;
        height: 2px;
    }
    .Home_container h1{
        font-size: 2.1rem;
        font-weight: normal;
        text-align: center;
    }
    .dropdown-toggle{
        border-radius: 5px;
        font-size: 1.5rem;
        margin-left: 0px;
        }
        .carousel{
            display: flex;
            max-width: 300px;
            max-height: 400px;
        }


}
@media screen and (max-width: 400px){
    .home_line{
        width: 290px;
        height: 2px;
    }
    .Home_container h1{
        font-size: 1.4rem;
        font-weight: normal;
        text-align: center;
    }
    .dropdown-toggle{
        border-radius: 5px;
        font-size: 1.4rem;
        margin-left: 0px;
    }

}
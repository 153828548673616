.banner{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 40px;
}
.banner_img{
    display: flex;
    width: 110%;
    height: 100vh;
    object-fit:cover;
    margin-top: -80px;
    margin-left: -140px;
}
.banner_content h1{
    font-size: 3rem;
    font-weight: 700;
    letter-spacing: 1px;
}
.banner_line{
    display: flex;
    width: 150px;
    height: 5px;
    background: linear-gradient(90deg, #67ebdb 0%, rgba(8, 255, 185, 0.85) 100%);
    margin-top: 10px;

}
.banner_content p{
    margin-top: 30px;
    font-size: 1rem;
    font-weight:400;
    max-width: 660px;
    line-height: 30px;
}
.HomeNews_btn{
    width: 150px;
    height: 40px;
    font-size: 1rem;
    border: 0px solid #00000000;
    box-sizing: border-box;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.42));
    border-radius: 3px;
    transition: 1s;
    color: rgb(5, 5, 5);
    position: relative;
}

/* btn */

.HomeNews_btn::before{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color:#4ecdc4;
    z-index: -1;
    transition: 1s;
    transform-origin: bottom ;
    transition-timing-function: cubic-bezier(0.5, 1.6, 0.4, 0.7);
}
.HomeNews_btn1::before{
    transform: scaleY(0);
}
.HomeNews_btn1:hover:before{
    transform: scaleY(1);

}

@media screen and (max-width: 800px){
    .banner{
        flex-wrap: wrap;
    }
    
    .banner_content h1{
        font-size: 2rem;
        letter-spacing: 0px;
        text-align: center;
    }
    .banner_img{
        display: flex;
        width: 100%;
        height:70vh;
        object-fit:fill;
        margin-top: -73px;
        margin-left: 0px;
    }
    .banner_line{
        display: flex;
        width: 250px;
        height: 5px;
        background: linear-gradient(90deg, #67ebdb 0%, rgba(8, 255, 185, 0.85) 100%);
        margin-top: 10px;
    }
    .banner_content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .banner_content p{
        font-size: 0.9rem;
        font-weight:400;
        max-width: 500px;
        line-height: 28px;
    }

}
@media screen and (max-width: 400px){
    .banner{
        flex-wrap: wrap;
    }
    .banner_img{
        display: flex;
        width: 100%;
        height:50vh;
        object-fit:cover;
        margin-top: -60px;
    }
    .banner_img_container{
        display: flex;
        width: 100%;
    }
    .banner_content{
        margin-top: 20px;
    }
    .banner_line{
        width: 180px;
        height: 5px;
        margin-top: -5px;
    }
    .banner_content h1{
        font-size: 1.4rem;
        letter-spacing: 0px;
    }
    .banner_content p{
        font-size: 0.9rem;
        font-weight:400;
        max-width: 340px;
        line-height: 25px;
        /* text-align: center; */
        margin-top: 19px;
    }
    .banner_content {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

}
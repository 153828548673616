.function{
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin-bottom: 100px;
    font-family: 'Roboto', sans-serif;
    margin-top: 10px;
}
.function_title{
    margin-bottom: 40px;
    font-size: 3rem;
    font-weight: 300;
    letter-spacing: 4px;

}
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');
.contact{
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 100px;
}
.contact h1{
    font-size: 3rem;
    font-weight: 300;
    letter-spacing: 4px;
}
.contact_container{
    display: flex;
    margin-top: 50px;
    align-items: center;
}
.contact_left{
    display: flex;
    justify-content: center;
    width: 500px;
    height: 400px;
    background-color: #c6f8f5;
    box-shadow: 2px 26px 17px 1px rgba(0, 0, 0, 0.11);
    border-radius: 10px; 
    align-items: center;
}
/* form */
.form{
    display: flex;
    flex-direction: column;
}
input, textarea{
    border: none;
    background-color: #c6f8f5;
    border-bottom: 1px solid rgb(0, 0, 0);
    margin-top: 20px;
    margin-bottom: 20px;
    outline: none;
    font-size: 1.5rem;
    font-weight: 300;
}
.contact_btn{
    border: 1px solid black;
    background: none;
    width: 300px;
    height: 60px;
    font-size: 1.3rem;
    border-radius: 3px;
    font-weight: 400;
    transition: 0.3s;
    letter-spacing: 1px;
}
.contact_btn:hover{
    background-color: rgb(22, 21, 21);
    transform-origin: bottom;
    color: rgb(255, 255, 255);
}
.contact_right{
    text-align: center;
}
.contact_right h2{
    font-size: 1.8rem;
    letter-spacing: 0.04em;
    font-weight: 300;
}
.contact_line{
    display: flex;
    height: 5px;
    width: 100%;
    background: linear-gradient(90deg, #FF0D9E 0%, #FF12E7 65.17%, rgba(64, 83, 255, 0.7) 100%);
    border-radius: 21px
}
.contact_left{
    margin-right: 300px;
}
.address{
    font-size: 0.8rem;
    max-width: 400px;
}

@media screen and (max-width: 800px){

    input, textarea{
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 1.3rem;
    }
    .contact_left{
        margin-right:20px;
    }
    .contact h1{
        font-size: 2.8rem;
        font-weight: 300;
        letter-spacing: 2px;
    }
    .contact_right h2{
        font-size: 1.6rem;
        margin-top: 30px;
    }
    .contact_btn{
        width: 250px;
        height: 50px;
        font-size: 1.1rem;
        border-radius: 3px;
    }
    .contact_container{
        flex-wrap: wrap;
        justify-content: center;
    }

}
@media screen and (max-width: 400px){
    input, textarea{
        font-size: 1.2rem;
    }
    .contact_btn{
        width: 220px;
        height: 40px;
        font-size: 0.9rem;
        border-radius: 3px;
    }
    .contact_right h2{
        font-size: 1.3rem;
    }
    .contact_left{
        display: flex;
        justify-content: center;
        width: 300px;
        height: 340px;
        box-shadow: 2px 26px 17px 1px rgba(0, 0, 0, 0.11);
        border-radius: 10px;
    }
    .address{
        font-size: 0.8rem;
        max-width: 300px;
    }

}